var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"41d7ecc09e9f44480d7e32d18d850145c14e0a47"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from '@sentry/nextjs'

// max 159 chars (200 max-40 sha-1)
const branch = process.env.VERCEL_GIT_COMMIT_REF?.slice(0, 159)

// fixed 40 chars
const sha = process.env.VERCEL_GIT_COMMIT_SHA

init({
  debug: process.env.NODE_ENV === 'development',
  dsn: 'https://381b62ea50e6459396e02786ee300b7f@o1272567.ingest.sentry.io/4505074673319936',
  environment: process.env.NEXT_SENTRY_ENV,
  tracesSampleRate: 0.75,
  release: branch && sha ? `${branch}-${sha}` : undefined,
  tunnel: '/api/sentry',
  // Ignore these errors as their sources does not appear to be in our code
  // https://pathwaymd.sentry.io/issues/4558429403/events/0dee60b4be75459a89006090a82067d7/?project=4505074673319936&query=is%3Aunresolved&referrer=next-event&statsPeriod=2h&stream_index=0a
  // https://pathwaymd.sentry.io/issues/4565363765/?project=4505074673319936&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=1h&stream_index=0
  ignoreErrors: [
    'This Suspense boundary received an update before it finished hydrating',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    '[Cloudflare Turnstile]',
  ],
})
