import React, { createContext, useContext, useEffect, useState } from 'react'

type LayoutContextType = {
  hasSidebarAd: boolean
  isExpanded: boolean | null
  setHasSidebarAd: (b: boolean) => void
  toggleSidebar: () => void
}

const LayoutContext = createContext<null | LayoutContextType>(null)

export const useLayout = () => {
  const context = useContext(LayoutContext)
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider')
  }
  return context
}

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [hasSidebarAd, setHasSidebarAd] = useState<boolean>(false)

  useEffect(() => {
    setIsExpanded((localStorage.getItem('sidebar-expanded') ?? '1') === '1')
  }, [])

  const toggleSidebar = () => {
    setIsExpanded((prev) => {
      const newValue = !prev
      localStorage.setItem('sidebar-expanded', newValue ? '1' : '0')
      if (prev === null) return true
      return newValue
    })
  }

  return (
    <LayoutContext.Provider
      value={{ hasSidebarAd, isExpanded, setHasSidebarAd, toggleSidebar }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
